<script>

import Icon from "../../Icon";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import DefaultCard_2 from "../default/DefaultCard_2";
import ElectricityMeterCard_1Table from "./ElectricityMeterCard_1Table";
import ElectricityMeterCard_2LatestValue from "./ElectricityMeterCard_2LatestValue";
import ElectricityMeterCard_2Button from "./ElectricityMeterCard_2Button";

export default {
    name: "ElectricityMeterCard_2",
    extends: DefaultCard_2,
    components: {
        HeaderComponent: DefaultCard_1Header,
        LatestValueComponent: ElectricityMeterCard_2LatestValue,
        MetricsButtonsComponent: ElectricityMeterCard_2Button,
        ChartComponent: ElectricityMeterCard_1Table,
        Icon
    },
    computed: {
        metricImage() {
            const deviceSlug = this.deviceObject?.getType()?.getSlug();

            try {
                const iconFile = require(`../../../assets/metrics/${deviceSlug}.png`);

                return {
                    "background-image": `url(${iconFile})`
                }
            } catch (e) {
                return null;
            }
        },
    }
}
</script>
