<script>

import Icon from "../../Icon";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import DefaultCard_3 from "../default/DefaultCard_3";
import ElectricityMeterCard_1Table from "./ElectricityMeterCard_1Table";
import ElectricityMeterCard_3ValuesList from "./ElectricityMeterCard_3ValuesList";

export default {
    name: "ElectricityMeterCard_3",
    extends: DefaultCard_3,
    components: {
        HeaderComponent: DefaultCard_1Header,
        ValuesListComponent: ElectricityMeterCard_3ValuesList,
        ChartComponent: ElectricityMeterCard_1Table,
        Icon
    },
}
</script>
<style scoped lang="less">
    .empty {
        display: none;
    }
</style>
