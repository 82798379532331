<script>

    import ElectricityMeterCard_1Button from "./ElectricityMeterCard_1Button"

    export default {
        name: "ElectricityMeterCard_2Button",
        extends: ElectricityMeterCard_1Button,
    }
</script>

<style scoped lang="less">

    .btn-ElectricityMeter {
        width: 160px;
        height: 45px;
        margin: 15px 0!important;
    }

</style>
