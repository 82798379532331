<template>
    <div :style="{backgroundColor:  chartColor || '#e3e3e3' }" class="gerkon-statistic">
        <div v-for="(item, index) in slicedTimeseries" :key="index" class="mb-2 pl-1 row">
            <div class="text-nowrap pl-1" style="width: 30%">
                <i class="fas fa-calendar-alt"></i>
                <strong> {{ item.date }}</strong>
            </div>
            <div class="text-nowrap pl-1" style="width: 25%">
                <i class="far fa-clock"></i>
                <strong> {{ item.time }}</strong>
            </div>
            <div style="width: 7%" v-if="item.load !== undefined">
                <div v-if="item.load === 0" class="text-danger text-center">
                    <i class="fas fa-power-off"></i>
                </div>
                <div v-if="item.load === 1" class="text-success text-center">
                    <i class="fas fa-power-off"></i>
                </div>
            </div>
            <div v-else class="text-muted text-center">
                N/A
            </div>
            <div v-if="item.value !== null" class="text-nowrap pl-1 font-weight-bold" style="width: 35%">
                {{ item.value}} {{item.units}}
            </div>
            <div v-else class="text-nowrap pl-1" style="width: 40%">
                <div class="gerkon-statistic-icon d-inline-flex justify-content-center align-items-center">
                    <i class="fas fa-lock text-muted"></i>
                </div>
                N/A
            </div>
        </div>
    </div>
</template>

<script>

import config from "../../../config";
import DefaultCard_1AbstractChart from "../default/DefaultCard_1AbstractChart";
import moment from "moment-mini";
import Icon from "../../Icon";

export default {
    name: "ElectricityMeterCard_1Table",
    components: {Icon},
    extends: DefaultCard_1AbstractChart,
    computed: {
        slicedTimeseries() {
            const consumption = this.timeseries?.find(item => item.getSlug() === 'consumption')?.getData()?.slice(0, 6)
            const load_state = this.timeseries?.find(item => item.getSlug() === 'load_state')?.getData()

            return consumption?.map(item => {
                item.load = load_state?.find( el => moment(item.getDate()).format('x') > moment(el.getDate()).format('x'))?.getValue()
                return {
                    date: moment(item.getDate()).format(config.dateTimeFormats.DDMMYYYY),
                    time: moment(item.getDate()).format(config.dateTimeFormats.HHmmss),
                    value: item.getValue(),
                    units: this.metricObject?.getUnits(),
                    load: item.load
                }
            })
        },
    },
}
</script>

<style scoped lang="less">

.gerkon-statistic {
    border-radius: 10px;
    height: 190px;
    padding: 1rem 1.5rem;
    font-size: 13px;
    color: #fff;

    &-item {
        margin: 5px 0;
    }

    &-icon {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        background-color: white;
    }

    &-time {
        min-width: 80px;
        font-weight: 600;
        line-height: 14px;
    }
}
</style>
