
<script>

import DefaultCard_2LatestValue from "../default/DefaultCard_2LatestValue";


export default {
    name: "ElectricityMeterCard_2LatestValue",
    extends: DefaultCard_2LatestValue,
}
</script>

<style lang="less" scoped>
.latest-value {
    width: 100%;
    padding: 10px 0 0;

    &__bg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: #ccc;
        color: #fff;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__value {
        text-align: center;
        font-weight: 700;
        font-size: 2rem;
        line-height: 1;
        color: #fff;
    }

    &__units {
        text-align: center;
        font-weight: 500;
        font-size: 1.2rem;
        color: #fff;
    }
}

</style>
