<template>
    <div class="electricityMeterButtons d-flex align-items-center justify-content-between">
        <button v-confirm="() => sendCommand('get consumption on mtx_electricity_meter_xxxx')"
                class="btn btn-ElectricityMeter my-2 mr-2"
                :disabled="processingLoadState || processing">

            <div v-if="processing" class="w-100 d-flex align-items-center justify-content-center">
                <div class="spinner-border spinner-border-sm text-white" role="status">
                    <span class="sr-only"></span>
                </div>
                <div>
                    {{ $t('Обновление...') }} {{ counter }}
                </div>
            </div>
            <div v-else>
                {{ $t('Обновить данные') }}
            </div>
        </button>

        <button
            v-confirm="() => sendCommand(!status ? 'turn ON load mtx_electricity_meter_xxxx' : 'turn OFF load mtx_electricity_meter_xxxx')"
            class="btn btn-ElectricityMeter btn-ElectricityMeter__load my-2"
            :disabled="processingLoadState || processing">

            <div v-if="processingLoadState" class="w-100 d-flex align-items-center justify-content-center">
                <div class="spinner-border spinner-border-sm text-white" role="status">
                    <span class="sr-only"></span>
                </div>
                <div>
                    {{ $t('Выполнение...') }} {{ counter }}
                </div>
            </div>

            <div v-else>
                {{ !this.status ? $t('Вкл нагрузку') : $t('Выкл нагрузку') }}
            </div>
        </button>
<!--        <RemoveModal :opened="openModal" title="вы уверены?" text="вы уверены" button-text="da"/>-->
    </div>


</template>

<script>

import DeviceObject from "../../../objects/DeviceObject";
import DownlinkService from "../../../services/DownlinkService";
import TemporaryOption from "@/classess/TemporaryOption";

const Downlinks = new DownlinkService();
const TempOption = new TemporaryOption();

export default {
    name: "ElectricityMeterCard_1Button",
    inject: ["getInjectedStatus"],
    props: {
        deviceObject: {
            type: DeviceObject,
            required: true
        }
    },
    data() {
        return {
            processing: false,
            processingLoadState: false,
            counter: null,
            loop: null,
            tempOptionName: "electricity_control_processing",
            tempOptionNameLoad: "load_control_processing",
            delay: 10000,
        }
    },
    methods: {
        async sendCommand(command = '') {
            this.counter = 10;
            if (command === 'get consumption on mtx_electricity_meter_xxxx') {
                TempOption.set(this.tempOptionName, true, this.delay)
                this.processing = true;
            } else {
                TempOption.set(this.tempOptionNameLoad, true, this.delay)
                this.processingLoadState = true
            }
            const result = await Downlinks.sendCommand(this.deviceObject?.getId(), command);
            if (result) {
                this.$toast.info(this.$t('Команда отправлена'));
            } else {
                this.$toast.error(this.$t('Ошибка!'));
                TempOption.remove(this.tempOptionName);
                TempOption.remove(this.tempOptionNameLoad);
                this.processing = false;
                this.processingLoadState = false;
                return;
            }
            this.done(this.counter)
        },
        done(counter) {
            this.counter = counter
            this.loop = setInterval(() => {
                this.counter--;

                if ( this.counter <= 0 ) {
                    TempOption.remove(this.tempOptionName);
                    TempOption.remove(this.tempOptionNameLoad);
                    clearInterval(this.loop);
                    this.processing = false;
                    this.processingLoadState = false;
                    this.$eventBus.emit(this.$eventBus.REFRESH_CARD_TIMESERIES, this.deviceObject.getDevEui());
                    this.$toast.success(this.$t('Команда выполнена'));
                }
            }, 1000);
        }
    },
    computed: {
        status() {
            return this.getInjectedStatus();
        }
    },
    mounted() {
        const isProcessing = TempOption.get(this.tempOptionName, false);
        const isProcessingLoad = TempOption.get(this.tempOptionNameLoad, false);
        const optionTtl = TempOption.getTtl(this.tempOptionName);
        const optionTtlLoad = TempOption.getTtl(this.tempOptionNameLoad);

        if (isProcessing) {
            this.processing = true;
            this.done(Math.floor(optionTtl / 1000));
        }
        if (isProcessingLoad) {
            this.processingLoadState = true;
            this.done(Math.floor(optionTtlLoad / 1000));
        }
    }
}
</script>

<style lang="less">
.btn-ElectricityMeter {
    width: 170px;
    height: 37px;
    background-color: #FF7F18;
    text-align: center;
    color: white;
    font-weight: 600;
    font-size: 14px;
    transition: all .3s ease-in-out;
    line-height: 1.1;
    padding: 0;
    margin: 8px 0 !important;

    &:hover {
        background-color: #EF6400;
    }

    &__load {
        background-color: #FC4A12;
        margin: 8px 0 8px 8px !important;

        &:hover {
            background-color: #DF2800;
        }
    }
}
</style>
