<template>
    <component :is="currentCardComponent"
               :deviceObject="deviceObject"
               :timeseries="timeseries"
               :isCurrentUserDevice="isCurrentUserDevice"
               @onLoad="() => this.loading = false"
               @deviceDoUpdate="$emit('deviceDoUpdate', $event)"
               @deviceDoShowGeoPosition="$emit('deviceDoShowGeoPosition', $event)"
               @deviceDoRemove="$emit('deviceDoRemove', $event)"/>
</template>

<script>
import ElectricityMeterCard_1 from "./ElectricityMeterCard_1";
import ElectricityMeterCard_2 from "./ElectricityMeterCard_2";
import ElectricityMeterCard_3 from "./ElectricityMeterCard_3";
import AbstractDefaultCard from "../default/AbstractDefaultCard";

export default {
    name: "AbstractElectricityMeterCard",
    extends: AbstractDefaultCard,
    components: {
        ElectricityMeterCard_1,
        ElectricityMeterCard_2,
        ElectricityMeterCard_3,
    },
    provide() {
        return {
            getInjectedStatus: () => this.getStatus
        }
    },
    computed: {
        currentCardComponent() {
            switch (this.currentCardDesign) {
                case 3:
                    return ElectricityMeterCard_3;
                case 2:
                    return ElectricityMeterCard_2;
                default:
                    return ElectricityMeterCard_1
            }
        },
        getStatus() {
            return this.timeseries
                ?.find(item => item.getSlug() === 'load_state')
                ?.getLatestData()
                ?.getValue() === 1;
        },
    }
}
</script>
