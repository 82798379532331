<script>

import Icon from "../../Icon";
import ElectricityMeterCard_1LatestValue from "./ElectricityMeterCard_1LatestValue";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import DefaultCard_1 from "../default/DefaultCard_1";
import ElectricityMeterCard_1Table from "../electricityMeter/ElectricityMeterCard_1Table";
import ElectricityMeterCard_1Button from "../electricityMeter/ElectricityMeterCard_1Button";

export default {
    name: "ElectricityMeterCard_1",
    extends: DefaultCard_1,
    components: {
        HeaderComponent: DefaultCard_1Header,
        LatestValueComponent: ElectricityMeterCard_1LatestValue,
        MetricsButtonsComponent: ElectricityMeterCard_1Button,
        ChartComponent: ElectricityMeterCard_1Table,
        Icon
    },
}
</script>
<style lang="less" scoped>

    .metricsButtons {
        .col-2 {
            display: none;
        }
        .col-8 {
            flex: 0 0 100%!important;
            max-width: 100%!important;
        }
    }

</style>
