<script>

import ElectricityMeterCard_1Button from "./ElectricityMeterCard_1Button";

export default {
    name: "ElectricityMeterCard_3Button",
    extends: ElectricityMeterCard_1Button,
}
</script>

<style scoped lang="less">
    .electricityMeterButtons {
        flex-direction: column;
        margin-bottom: 12px;
    }

    .btn-ElectricityMeter {
        width: 220px;
        height: 45px;
        padding: 7.2px 3px;
        margin: 15px 0!important;
    }
</style>
